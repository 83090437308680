<template>
  <v-container
    class="px-sm-10 py-sm-10"
    fluid
    style="
      min-height: 100vh;
      background: #E6E6E6;
    "
  >
    <v-card class="mx-sm-3 mb-4 px-3 py-4">
      <v-row>
        <v-col class="font-common-black-bold">
          Rekap Aset
        </v-col>
      </v-row>

      <v-row v-if="recap !== null">
        <v-col
          v-for="(v, n) in recap"
          :key="n"
          class="py-4"
          cols="6"
          sm="2"
        >
          <div
            class="d-flex px-3"
            style="
            flex-direction: column;
            width: auto;
            height: 96px;
            box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.15);
            border-radius: 8px;
          "
          >
            <div
              class="font-common-black-bold"
              style="font-size: 44px"
            >
              {{ v }}
            </div>

            <div
              class="font-common-black-bold"
              style="font-size: 12px"
            >
              {{ n }}
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card>

    <v-card class="px-4 mx-3">
      <v-data-table
        :headers="headers"
        :items="aset"
        :items-per-page="25"
        class="elevation-0"
        mobile-breakpoint="100"
        hide-default-footer
      >
        <template v-slot:top>
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
              class="mt-2 mb-3"
            >
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Cari"
                color="purple"
                single-line
                hide-details
                clearable
                outlined
                dense
                @keyup="filterData"
                @click:append="filterData"
                @click:clear="filterClear"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="2"
              class="mt-2 mb-3"
            >
              <v-select
                v-model="status"
                item-text="text"
                item-value="value"
                :items="dropdown_status"
                :menu-props="{ bottom: true, offsetY: true }"
                label="Status Aset"
                color="purple"
                outlined
                dense
                @change="statusChange"
              />
            </v-col>
          </v-row>
        </template>

        <template v-slot:item.price_year="{ item }">
          <div class="currency-format">
            <span>
              Rp
            </span>

            <span>
              {{ (item.price_year || 0) | dotThousandSeparator }}
            </span>
          </div>
        </template>

        <template v-slot:item.price_month="{ item }">
          <div class="currency-format">
            <span>
              Rp
            </span>

            <span>
              {{ (item.price_month || 0) | dotThousandSeparator }}
            </span>
          </div>
        </template>

        <template v-slot:item.price_day="{ item }">
          <div class="currency-format">
            <span>
              Rp
            </span>

            <span>
              {{ (item.price_day || 0) | dotThousandSeparator }}
            </span>
          </div>
        </template>

        <template v-slot:item.price_print="{ item }">
          <div class="currency-format">
            <span>
              Rp
            </span>

            <span>
              {{ (item.price_print || 0) | dotThousandSeparator }}
            </span>
          </div>
        </template>

        <template v-slot:item.ads_tax_month="{ item }">
          <div class="currency-format">
            <span>
              Rp
            </span>

            <span>
              {{ (item.ads_tax_month || 0) | dotThousandSeparator }}
            </span>
          </div>
        </template>

        <template v-slot:item.price_install="{ item }">
          <div class="currency-format">
            <span>
              Rp
            </span>

            <span>
              {{ (item.price_install || 0) | dotThousandSeparator }}
            </span>
          </div>
        </template>

        <template v-slot:item.ads_tax_year="{ item }">
          <div class="currency-format">
            <span>
              Rp
            </span>

            <span>
              {{ (item.ads_tax_year || 0) | dotThousandSeparator }}
            </span>
          </div>
        </template>

        <template v-slot:item.status_string="{ item }">
          <v-chip :color="'#' + item.status_color">
            <span class="text-light">
              {{ item.status_string }}
            </span>
          </v-chip>
        </template>

        <template v-slot:item.actions="{ item }">
          <div
            class="action-container"
            @click="toDetail(item)"
          >
            <v-icon
              class="action-icon"
              style="font-size: 16px"
            >
              fas fa-eye
            </v-icon>
          </div>
        </template>

        <template v-slot:footer>
          <div class="text-center my-5 py-3">
            <v-pagination
              v-model="page"
              :length="pageCount"
              color="purple"
              :total-visible="5"
              @input="onChangePage"
            />
            <span style="font-size: 12px; font-style: italic;">
              {{ itemCount + ' dari ' + itemTotal + ' ditampilkan' }}
            </span>
          </div>
        </template>
      </v-data-table>
    </v-card>

    <!-- DIALOG -->
    <v-dialog
      v-model="dialog"
      max-width="600"
    >
      <v-card class="px-4 py-4">
        <v-row>
          <v-col class="text-center">
            <span style="font-weight: bold">
              Informasi Perusahaan Vendor
            </span>
          </v-col>

          <v-col class="text-right">
            <span
              style="cursor: pointer"
              @click="dialog = false"
            >
              X
            </span>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapState } from 'vuex'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    data: () => ({
      tab: null,
      tabDialog: null,
      dialog: false,

      // Verifikasi Aset:
      search: '',
      headers: [
        { text: 'Nama Mitra', align: 'start', value: 'prt_name_legal' },
        { text: 'Kode Aset', value: 'code', sortable: false },
        { text: 'Tipe', value: 'type' },
        { text: 'Ukuran', value: 'dimension' },
        { text: 'Kecamatan', value: 'add_kec' },
        { text: 'Kota/ Kab', value: 'add_kot' },
        { text: 'Status', value: 'status_string' },
        { text: 'Harga Tahunan', value: 'price_year' },
        { text: 'Harga Bulanan', value: 'price_month' },
        { text: 'Harga Harian', value: 'price_day' },
        { text: 'Harga Cetak', value: 'price_print' },
        { text: 'Harga Pasang', value: 'price_install' },
        { text: 'Pajak Tahunan', value: 'ads_tax_year' },
        { text: 'Pajak Bulanan', value: 'ads_tax_month' },
        { text: '', value: 'actions', sortable: false },
      ],
      aset: [],
      dropdown_status: [],
      static_status: {
        text: 'Semua',
        value: '',
      },
      status: '',

      page: 1,
      pageCount: 0,
      itemCount: 0,
      itemTotal: 0,

      recap: null,
    }),

    computed: {
      ...mapState({
        itemsPerPage: state => state.setting.itemsPerPage,
      }),
    },

    watch: {
    },

    created () {
      this.initialize(1, this.itemsPerPage)
      this.getAsetRecap()
      this.getStatusInit()
    },

    methods: {
      initialize (page, limit, filter, status) {
        // Check user menus
        // const userMenus = localStorage.getItem('userMenus').split(',')

        // if (userMenus.includes('CUSTOMER_VERIFICATION')) {
        //   this.isAllowVerification = true
        // }

        const vm = this

        const requestBody = {
          page: page,
          limit: limit,
          searchq: filter,
          status: status,
        }

        axios.post('/v2/t/asset', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.aset = res.data.data.list
            console.log(vm.aset)
            // Pagination:
            vm.pageCount = res.data.data.pagination.page_total
            vm.itemCount = res.data.data.pagination.item_count
            vm.itemTotal = res.data.data.pagination.item_count_total
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      getAsetRecap () {
        const vm = this

        const requestBody = {}

        // "AKTIF": 77,
        // "TIDAK_AKTIF": 7,
        // "VERIFIKASI": 13,
        // "VERIFIKASI_GAGAL": 8,
        // "DRAFT": 16,
        // "NONAKTIF": 2

        axios.post('/v2/t/asset/recap', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.recap = res.data.data
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      getStatusInit () {
        const vm = this

        axios.post('v2/t/asset/init').then((res) => {
          if (res.data.status === 200) {
            vm.dropdown_status = res.data.data.dropdown_status
            vm.dropdown_status.unshift(this.static_status)
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      toDetail (item) {
        // console.log(item)
        this.$router.push({ name: 'AsetDetail', params: { id: item.hash } })
      },

      onChangePage (e) {
        this.initialize(e, this.itemsPerPage, this.search, this.status)
      },

      filterData () {
        if (this.timer) {
          clearTimeout(this.timer)
          this.timer = null
        }
        this.timer = setTimeout(() => {
          console.log(this.search)
          this.initialize(1, this.itemsPerPage, this.search, this.status)
        }, 800)
      },

      filterClear () {
        this.initialize(1, this.itemsPerPage, '', this.status)
      },

      statusChange () {
        this.initialize(1, this.itemsPerPage, this.search, this.status)
      },
    },
  }
</script>

<style scoped>
.font-common {
  font-family: Lato;
  font-size: 16px;
  color: #808080;
}

.font-common-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #808080;
}

.font-common-black {
  font-family: Lato;
  font-size: 16px;
  color: #333333;
}

.font-common-black-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
}

.font-common-white {
  font-family: Lato;
  font-size: 16px;
  color: #FFFFFF;
}

.font-common-white-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #FFFFFF;
}

.action-container {
  background: #f2f2f2;
}

.action-container:hover {
  background: #7A1878;
}

.action-container:hover > .action-icon {
  color: #f2f2f2;
}

.action-icon {
  color: #7A1878;
}

tbody > tr:hover {
  background-color: transparent !important;
}
</style>
